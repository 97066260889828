import Hero from '../Components/Hero/Hero'
import { UseTitle } from '../Components/Hero/UseTitle';
import Popular from '../Components/Popular/Popular';


const Home = () => {
  UseTitle("Celebration Comforts");
  return (
    <div >
      <Hero/>
      <Popular/>
    </div>
  )
}

export default Home;