import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Item.css';
import { ItemOrderQuantityToggle } from './ItemOrderQuantityToggle';
import { Button, Image } from 'react-bootstrap';


const Item = (props) => {

    const [orderItem_quantity, setOrderItemQuantity] = useState(1);
    const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
    const [newPrice, setNewPrice] = useState(props.newPrice);
    const [oldPrice, setOldPrice] = useState(props.oldPrice);
    const [unit, setUnit] = useState(props.defaultUnit);

   useEffect(() => {
      setUnit(props.defaultUnit);
      setNewPrice(props.newPrice);
      setOldPrice(props.oldPrice);
      setOrderItemQuantity(1);
    }, [props.menu])

    const setQuantityDecrease = () => {
        if((orderItem_quantity > 1 || orderItem_quantity === 1) && props.selectedUnit === unit){
          setOrderItemQuantity(orderItem_quantity - 1);
          props.updateQuantity(props.id, orderItem_quantity - 1, unit, newPrice);
          
        } else {
          setOrderItemQuantity(1);
        } 
    };

    const setQuantityIncrease = () => {   
 
        if(orderItem_quantity < props.stock ){
          setOrderItemQuantity(orderItem_quantity + 1) ;
         props.updateQuantity(props.id, orderItem_quantity + 1, unit, newPrice);
          
        } else {
          setOrderItemQuantity(props.stock);
        }         
    };

    const handleClick = (old_Price, new_Price, selectedUnit) => {
      setUnit(selectedUnit);
      setNewPrice(new_Price);
      setOldPrice(old_Price);
      setOrderItemQuantity(1);
      props.updateQuantity(props.id, orderItem_quantity, selectedUnit, new_Price);
    };

  return (
    <div className='item'>
        <Link to={`/product/${props.id}`}><Image src={`${baseURL}/image/${props.mainImage}`} alt={props.productName} rounded />  </Link>
        <p>{props.productKannadaName} / {props.productName} </p>
        <p >       
          { (props.stock === 0) ? (
            <span className="item-outofstock">Not Available</span>
          ) : (
          <span className="item-instock">Available</span>
          ) }        
        </p>
        <div className='item-prices'>
            <div className="item-price-new">
                Rs. {newPrice}
            </div>
            <div className="item-price-old">
                Rs. {oldPrice}
            </div>
        </div>
        {(typeof props.menu !== "undefined") &&
            <div className='unit-prices'>
            { Object.entries(props.unitOfMeasure).map(([key,value], i) => (
              <Button key ={i} variant="outline-secondary" className={unit === key ?'unit-prices-button-active':'unit-prices-button'} onClick={() => {handleClick(value[0],value[1],key)}} >{key}</Button>
            ))
            }
            </div>
        }
        {/** Add or reduce product quantity */}
        {!(props.stock === 0 ) && (props.showCounter) && <ItemOrderQuantityToggle
                                                orderItem_quantity={orderItem_quantity}
                                                setQuantityDecrease={setQuantityDecrease}
                                                setQuantityIncrease={setQuantityIncrease}
                                                />
        }
        
    </div>
  )
}

export default Item