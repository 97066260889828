import { useState } from "react";
import { useEffect } from "react";
import { DashboardCard } from "./DashboardCard";
import { DashboardEmpty } from "./DashboardEmpty"
import { toast } from 'react-toastify';
import axios from 'axios';
import './Dashboard.css';

export const Dashboard = () => {
  const [orders, setOrders] = useState([]);

  //const token = JSON.parse(sessionStorage.getItem("token"));
  const userId = (sessionStorage.getItem("userId"));
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;

  useEffect(() => {
    async function fetchOrders(){
      axios.get(`${baseURL}/orders/retrieve/${userId}`)
                .then((response) => {

                  toast.success(response.data.message);
                  const data =  response.data;
                  setOrders(data);
                })
                .catch((err) => toast.error(err));
      
    }
    fetchOrders();
  }, [userId]);

  return (
    <main>
      <section className="dashboard">
        <h3 >My Dashboard</h3>
      </section>

      <section className="dashboard_card">
        { orders.map((order) => (
          <DashboardCard key={order.id} order={order} />
        )) }
      </section>

      <section>
        { !orders.length && <DashboardEmpty /> }
      </section>

    </main>
  )
}