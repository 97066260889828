import React from 'react'
import { FaMinus, FaPlus } from "react-icons/fa";


export const ItemOrderQuantityToggle = ({orderItem_quantity, setQuantityDecrease, setQuantityIncrease}) => {
  return (
    <div className='item-toggle'>
        <button onClick={() => setQuantityDecrease()}><FaMinus/></button>
        <div className='item-toggle-quantity'>{orderItem_quantity}</div>
        <button onClick={() => setQuantityIncrease()}><FaPlus/></button>
    </div>
  )
}
