export const DashboardEmpty = () => {
    return (
      <section className="dashboardEmpty">
          <div >
              
              <p>Oops! Your order dashboard looks empty!</p>
              <p>Add products to your cart from our store collection.</p>
          </div>
          <a href="/" type="button" >Continue Shopping </a>
      </section>  
    )
  }