import React, { useState } from 'react';
import './CSS/LoginSignUp.css'
import { SignUp } from '../Components/SignUp/SignUp';
import { Login } from '../Components/Login/Login';
import { UseTitle } from '../Components/Hero/UseTitle';
import { useLocation } from 'react-router-dom';

const LoginSignUp = () => {
  UseTitle("Login/SignUp");
  const [signUpShow, setSignUpShow] = useState(false);
  const { state } = useLocation();

  if(signUpShow && (state!=null && !state.signupStatus)){
    setSignUpShow(false)
  }

  return (

    <>{
      (signUpShow ? <SignUp setSignUpShow={setSignUpShow}/> : <Login setSignUpShow={setSignUpShow}/>)
    }</>

    
  )
}

export default LoginSignUp;