import React, { useContext, useEffect, useState } from 'react';
import { PoojaContext } from '../Context/PoojaContext';
import { useParams } from 'react-router-dom';
import Breadcrum from '../Components/Breadcrums/Breadcrum';
import ProductDisplay from '../Components/ProductDisplay/ProductDisplay';
import DescriptionBox from '../Components/DescriptionBox/DescriptionBox';
import { UseTitle } from '../Components/Hero/UseTitle';


const Product = () => {
  UseTitle("Our Trusted Products");
  const {products} = useContext(PoojaContext);
  const {productId} = useParams();
  const product = (products.find((e) => e.id === (productId)));
  useEffect (() => {
    if(product !=null){
        localStorage.setItem("product", JSON.stringify(product));
    }
    
}, [productId])



  return (
    <div>
      <Breadcrum product={product} />
     
      <ProductDisplay product={product} productId={productId}/>

      <DescriptionBox />
    </div>
  )
}

export default Product