import React from 'react'
import { OrderSuccess } from './OrderSuccess';
import { useLocation } from 'react-router-dom';
import { OrderFail } from './OrderFail';

export const OrderSummary = () => {
    const { state } = useLocation();

  return (
    <main>
      { state.status ? <OrderSuccess data={state.data} /> : <OrderFail /> }
    </main>
  )
}
