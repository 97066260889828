import { Link } from "react-router-dom"

const CartEmpty = () => {
  return (
    <section className="cartEmpty">
        <div >
            <p ></p>
            <p>Oops! Your cart looks empty!</p>
            <p>Add products to your cart from our store collection.</p>
        </div>
        <Link to="/" type="button" >Continue Shopping </Link>
    </section>
  )
}

export default CartEmpty;