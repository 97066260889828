import React, { useContext } from 'react'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import './Popular.css'
import Item from "../Item/Item"
import { PoojaContext } from '../../Context/PoojaContext';

const Popular = () => {
  const {products} = useContext(PoojaContext);
  return (
    <Container className="popular">
      <Row>
        <Col> <h1>POPULAR AMONG ALL</h1> <hr /></Col>
      </Row>
      <Row>

        {products.map((item, i) => {
          
          return (item.isPopular) &&  <Col xs={12} md={6} lg={3} key={i}>
            <Item  id={item.id} productName={item.productName} productKannadaName={item.productKannadaName} mainImage={item.mainImage}
              newPrice={item.defaultNewPrice} oldPrice={item.defaultOldPrice} defaultUnit={item.defaultUnitOfMeasure} unitOfMeasure={item.unitOfMeasure} /> </Col>
        })}

      </Row>
    </Container>
  )
}

export default Popular