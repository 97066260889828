import React, { useContext, useEffect, useState } from 'react'
import { PoojaContext } from '../../Context/PoojaContext';
import Item from '../Item/Item';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const CelebrationDisplay = ({subCategories}) => {
  const { products, addToCart, addToUserSelectedSubCategories,cartItems } = useContext(PoojaContext);
  const { celebrationName, updateCelebrationName} = useContext(PoojaContext);
  const initialFilteredData = products.filter((product) => (product.subCategories).includes(celebrationName));
  const [filteredProducts, setFilteredProducts] = useState(initialFilteredData);
  const [menu, setMenu] = useState(subCategories[0]);
  const [selectedUnit, setSelectedUnit] = useState();


  useEffect(() => {
     function fetchFilteredProductData() {
      try {
        onclickSideMenu(menu)
        let prods = products.filter((product) => (product.subCategories).includes(celebrationName));
        
        setFilteredProducts(prods)
       
      } catch (error) {
        toast.error('Error fetching products:', error);
      }
    }
    fetchFilteredProductData();
  }, [celebrationName,products]);

  useEffect(() => {
    setMenu(subCategories[0]);
  }, [subCategories]);



  const updateQuantity = (id, updatedQuantity, selectedUnit, price) => {
    
    setFilteredProducts(filteredProducts.map((prod) => {
      if (prod.id === id) {
        
        return { ...prod, quantity: updatedQuantity, selectedUnit: selectedUnit, price: price };

      }
      return prod;
    }));
  }

  const onclickSideMenu = (menuName) => {
    setMenu(menuName);
    updateCelebrationName(menuName);
    
  }

  const addAllItemsToCart = () => {
    filteredProducts.map((item) => {
    
      if (item.quantity >= 0) {
        
        //for (let index = 0; index < item.quantity; index++) {
          addToCart(item.id, item);
          addToUserSelectedSubCategories(celebrationName);
        //}
      } else if (item.stock > 0) {
        
        addToCart(item.id,{...item, quantity: 1, selectedUnit: item.defaultUnitOfMeasure, price: item.defaultNewPrice });
        addToUserSelectedSubCategories(celebrationName);
      }

    })
  }

  return (
    <Container fluid>
    <Row >
      <Col  md={3} lg={2} sm={4} className='celebrations_sidemenu'>
      <Nav defaultActiveKey="/home" className="flex-column">
      {subCategories.map((menuName, i) => (
        <Nav.Link className={menu === menuName ? 'sidemenu_link_active' : 'sidemenu_link'} onClick={() => {onclickSideMenu(menuName)}} key ={i}>{menuName}</Nav.Link>
      ))}
    </Nav>
      </Col>
      <Col  md={9} lg={10} sm={8}>
      
      <div >
        <p><span> Showing {filteredProducts.length} </span> out of {filteredProducts.length} Products for {celebrationName}</p>      
      </div>
      {/** Display each product in the filtered product list*/}
      <div className="celebrations-products" >
        {filteredProducts.map((item, i) => {
          return <Item key={i} id={item.id} productName={item.productName} productKannadaName={item.productKannadaName} mainImage={item.mainImage}
            newPrice={item.defaultNewPrice} oldPrice={item.defaultOldPrice} defaultUnit={item.defaultUnitOfMeasure} stock={item.stock} updateQuantity={updateQuantity} unitOfMeasure={item.unitOfMeasure}
            showCounter="true" menu={menu}/>
        })
        }
      </div>
      <div className="celebrationdisplay-right">
        {((initialFilteredData.length) > 0) && (
          <>
          <Button variant="primary" onClick={() => { addAllItemsToCart() }}>ADD ALL ITEMS TO CART</Button>
          <p className="note">
          Note : Product images are for representation purposes only. Actual products may differ.  
         </p>
         </>
        )
        }
       
      </div>
      
      </Col>
     
    </Row>
   
  </Container>



    
  )
}
