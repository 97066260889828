import React, { useContext, useState } from 'react';

import star_empty_icon from '../Assets/star_empty_icon.png'
import small_star_icon from '../Assets/small_star.png'
import { PoojaContext } from '../../Context/PoojaContext';
import './ProductDisplay.css';
import { Button, Col, Container, Row } from 'react-bootstrap';


const ProductDisplay = ({product}) => {
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const {addToCart} = useContext(PoojaContext);
  const prod = ((product === null || (typeof product === "undefined") || product.length === 0) ?  JSON.parse(localStorage.getItem("product")) : product );
  const [newPrice, setNewPrice] = useState(prod.defaultNewPrice);
  const [oldPrice, setOldPrice] = useState(prod.defaultOldPrice);
  const [unit, setUnit] = useState(prod.defaultUnitOfMeasure);
    const handleClick = ( old_Price, new_Price,selectedUnit) => {
      setUnit(selectedUnit);
      setNewPrice(new_Price);
      setOldPrice(old_Price);
    };

  return (
    <Container fluid >
       <Row>
        <Col md={9} lg={10} sm={10} xs={8}>
     
    <div className='productdisplay'>
      <div className="productdisplay-left">
        <div className="productdisplay-img-list">
         <img src={`${baseURL}/image/${prod.mainImage}`} alt="" />
          <img src={`${baseURL}/image/${prod.mainImage}`} alt="" />
        </div>
        <div className="productdisplay-img">
         <img className='productdisplay-main-img' src={`${baseURL}/image/${prod.mainImage}`} alt="" />
        </div>
      </div>
      <div className="productdisplay-right">
        <h1>{prod.productName}</h1>
        <h1>{prod.productKannadaName}</h1>
        <div className="productdisplay-right-star">
          <img src={small_star_icon} alt="" />
          <img src={small_star_icon} alt="" />
          <img src={small_star_icon} alt="" />
          <img src={small_star_icon} alt="" />
          <img src={star_empty_icon} alt="" />
          <p>99</p>
        </div>
        <div className="productdisplay-stock">       
          { (prod.stock === 0) ? (
            <span className="item-outofstock">Not Available</span>
          ) : (
          <span className="item-instock">Available</span>
          ) }        
        </div>
        <div className="productdisplay-right-prices">
          <div className="productdisplay-right-price-old">Rs. {oldPrice}</div>
          <div className="productdisplay-right-price-new">Rs. {newPrice}</div>
        </div>
        <div className='productdisplay-unit-prices'>
        { Object.entries(prod.unitOfMeasure).map(([key,value], i) => (
           <Button key ={i} variant="outline-secondary" className={unit === key ?'unit-prices-button-active':'unit-prices-button'} onClick={() => {handleClick(value[0],value[1],key)}} >{key}</Button>
        ))
        }
          
        </div>
        <div className="productdisplay-right-description">
          {prod.description}
        </div>
        { (prod.stock === 0) ? (
            <Button className='productdisplay-right-button' disabled>ADD TO CART</Button>
          ) : (
            <Button className='productdisplay-right-button' onClick={() => { addToCart(prod.id,{...product, quantity: 1, selectedUnit: unit, price: newPrice }) }  }>ADD TO CART</Button>
          ) } 
        
      </div>
    </div>
    </Col>
    </Row>
    </Container>
  )
}

export default ProductDisplay;