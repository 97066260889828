import { useContext, useEffect, useState } from 'react';
import './CartItems.css';
import { PoojaContext } from '../../Context/PoojaContext';

import remove_icon from '../Assets/remove_icon.png';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaMinus, FaPlus } from "react-icons/fa";
import { Image } from 'react-bootstrap';



const CartItems = () => {

    const {getCartTotalAmount, products, cartItems,addToUserSelectedSubCategories, addToCart, removeFromCart, reduceQuantityForanItemFromCart, clearCart, loginUserDetails, userSelectedSubCategories,getTotalCartItems} = useContext(PoojaContext);
    const token = JSON.parse(sessionStorage.getItem("token"));
    const userId = sessionStorage.getItem("userId");
    const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
    const navigate = useNavigate();
    const [cart_items, setCartItems] = useState(cartItems);
    const [updateCart, setUpdateCart] = useState(1);

    const setQuantityDecrease = (item) => { 
        if((item.quantity > 1 || item.quantity === 1) ){         
          reduceQuantityForanItemFromCart(item.id, item.selectedUnit);
          setUpdateCart(updateCart+1);
        } 
    };

    const setQuantityIncrease = (item) => {   
        if((item.stock - item.quantity) > 0){
         addToCart(item.id, item);
         setUpdateCart(updateCart+1);
         getTotalCartItems();
          
        } else {
            toast.info("no stock:"+item.stock)
        }       
    };

    useEffect(() => {
        setCartItems(cartItems);
        navigate('/cart')
    },[updateCart])
  
    async function handleOrderSubmit(event) {
        event.preventDefault();
        try{
            let orderItems = [];
            cartItems.map((item) => {
            
                orderItems = ([...orderItems, {
                    productId: item.id,
                    subCategories: userSelectedSubCategories,
                    quantity: item.quantity,
                    productName: item.productName,
                    mainImage: item.mainImage,
                    newPrice: item.price,
                    unit: item.selectedUnit
                }])
         } )

        const order = {emailAddress: loginUserDetails.emailAddress,
                phoneNumber: loginUserDetails.phoneNumber,
                address: loginUserDetails.address,
                userId: loginUserDetails.userId,
                totalAmount: getCartTotalAmount(),
                orderItems:orderItems}

        axios.post(`${baseURL}/orders`, order)
        .then((response) => {
            if(response.status === 200){
                const orderDetails = response.data;
                clearCart();
                navigate("/order-summary", { state: {data: orderDetails, status: true} });
              }
        })
        .catch((err) => toast.error(err));
  
        } catch(error){
            navigate("/order-summary", { state: {status: false} });
        } 
    }

  return (
    <Container fluid>
    <div className='cartitems'>
        <div className="cartitems-format-main">
            <p>Product</p>
            <p>Title</p>
            <p>Price</p>
            <p>Quantity</p>
            <p>Units</p>
            <p>Total</p>
            <p>Remove</p>
        </div>
        <hr />
        {cart_items.map((item, i) => {
         
            if( item.quantity > 0) 
            {
                return <div key={i}>
                            <div className="cartitems-format cartitems-format-main">
                            <Image className="carticon-product-icon" src={`${baseURL}/image/${item.mainImage}`} alt={item.productName} rounded />
                               
                                <p>{item.productName}</p>
                                <p>Rs. {item.price}</p>
                                
                                 {/** 
                                  * Add or reduce product quantity */}
                                 <div className='cart-item-toggle'>
                                    <button onClick={() => setQuantityDecrease(item)}><FaMinus/></button>
                                    <div className='cart-item-toggle-quantity'>{item.quantity}</div>
                                    <button onClick={() => setQuantityIncrease(item)}><FaPlus/></button>
                                </div>
        
                                <p>{item.selectedUnit}</p>
                                <p>Rs. {item.price*item.quantity}</p>
                                
                                <img className='cartitems-remove-icon' onClick={() => removeFromCart(item.id,item.selectedUnit)} src={remove_icon} alt="" />
                            </div>
                            <hr />
                        </div>
            }
            return null;
        })}
        <div className="cartitems-down">
            <div className="cartitems-total">
                <h1>Cart Total</h1>
                <div>
                    <div className="cartitems-total-items">
                        <p>Sub Total</p>
                        <p>Rs. {getCartTotalAmount()}</p>
                    </div>
                    <hr />
                    <div className="cartitems-total-items">
                        <p>Shipping Fee</p>
                        <p>Free</p>
                    </div>
                    <hr />
                    <div className="cartitems-total-items">
                        <h3>Total</h3>
                        <h3>Rs. {getCartTotalAmount()}</h3>
                    </div>
                    <button onClick={handleOrderSubmit}>Place the Order</button>
                </div>
                    
            </div>
        </div>
    </div>
    </Container>
  )
}

export default CartItems;