import React from 'react'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './Footer.css'

const Footer = () => {
  return (

    <Container >
      
      <Row>
        
        <Col> <h4>ಉತ್ಸವ ಸೌಖ್ಯ</h4></Col>
        <Col>
        <ul className="footer-links">
            <li>About</li>
            <li>Contact</li>
        </ul></Col>
      </Row>
    </Container>

    
  )
}

export default Footer