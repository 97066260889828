import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;

const apiUrl = `${baseURL}/products/1/10`;
const apiCategoriesUrl = `${baseURL}/categories`;
const apiImageUploadUrl = `${baseURL}/image`

export const getProducts = async () => {
  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    throw error;
  }

}


export const getCategoriess = async (imageData) => {
  try {
    const response = await axios.get(apiCategoriesUrl, imageData);
    
    return response.data;
  } catch (error) {
    throw error;
  }

}

export const imageUpload = async () => {
  try{
    const response = await axios.post(apiImageUploadUrl);

    return response.data;
  }catch (error) {
    throw error;
  }
}
