
import CartItems from '../Components/CartItems/CartItems';
import CartEmpty from '../Components/CartItems/CartEmpty';
import { PoojaContext } from '../Context/PoojaContext';
import { useContext } from 'react';
import { UseTitle } from '../Components/Hero/UseTitle';


const Cart = () => {
  UseTitle("Your Shopping Cart");
  const {getTotalCartItems} = useContext(PoojaContext);

  return (
    <div>
      { (getTotalCartItems() === 0) ? <CartEmpty/> : <CartItems/> }   
    </div>
  )
}

export default Cart