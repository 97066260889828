import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import PoojaContextProvider from './Context/PoojaContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PoojaContextProvider>
   

    <ToastContainer closeButton={false} autoClose={3000} position={"top-center"} />
    
    <App />
   
  </PoojaContextProvider>
);


