import React from 'react';
import './DescriptionBox.css'

const DescriptionBox = () => {
  return (
    <div className='descriptionbox'>
        <div className="descriptionbox-navigator">
            <div className="descriptionbox-navbox">Description</div>
            <div className="descriptionbox-navbox fade">Reviews (99)</div>
        </div>
        <div className="descriptionbox-description">
            <p>
                Give some Description about Product
            </p>
        </div>
    </div>
  )
}

export default DescriptionBox