import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useRef } from "react";
import bcrypt from 'bcryptjs';
import { toast } from 'react-toastify';
import { PoojaContext } from '../../Context/PoojaContext';


export const Login = ({ setSignUpShow }) => {
  const {updateLoginUserDetails} = useContext(PoojaContext);
  const userName = useRef();
  const password = useRef();
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const URL = `${baseURL}/user/login`;
  const role = sessionStorage.getItem("role");


  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailAddress = userName.current.value;
    const enteredPassword = password.current.value;
    const authDetail = {
      emailAddress: emailAddress
    }

    try {

      const response = await axios.post(URL, authDetail);
     // const accessToken = false; // need to get accesstoken from response later
      if (response) {

        if (response.status === 200) {

          const logindetails = response.data;
          if (logindetails != null) {
            const passwordCompare = bcrypt.compareSync(enteredPassword, logindetails.password);
            if (passwordCompare) {
              // accessToken ? navigate("/dashboard") : toast.error(response.data.message);

              const accessTokenData = JSON.stringify("accesstoken");//JSON.stringify(data.accessToken)

              //if(accessToken){
              sessionStorage.setItem("token", accessTokenData);
              sessionStorage.setItem("userId", logindetails.userId);
              sessionStorage.setItem("userName", logindetails.username);
              sessionStorage.setItem("role", logindetails.role);
              updateLoginUserDetails(logindetails);

              //}
              toast.done("User Logged In successfully")
              
              if(logindetails.role === "ADMIN") {
                
                navigate("/addproducts");
              } else {
                
                navigate("/dashboard");
              }
              
            } else {
              toast.error("Login failed - Invalid Credentials")
            }

          }
        }
      }

    } catch (error) {

      if (!error?.response) {
        toast.error('No Server Response');
      } else {
        toast.error('Login Failed - Try again with valid credentials')
      }

    }
  };

  return (
    <Form sm={12} md={6} className='login-signup-container' onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label >UserId</Form.Label>
        <Form.Control ref={userName} className="login-signup-fields" type="text" required placeholder="Enter email/Mobile Number" />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control ref={password} className="login-signup-fields" type="password" required placeholder="Password" />
      </Form.Group>

      <Button variant="secondary" type="submit">
        Submit
      </Button>
      <Form.Label className="login-signup-label">New to UthsavaSoukhya? <Link onClick={() => { setSignUpShow(true) }} to="/login">Signup Now </Link></Form.Label>

    </Form>

  )
}
