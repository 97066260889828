import React from 'react'
import { useLocation, useParams } from 'react-router-dom';

import { CelebrationDisplay } from '../Components/Celebrations/CelebrationDisplay'
import './CSS/Celebrations.css'
import { UseTitle } from '../Components/Hero/UseTitle';

export const Celebrations = () => {
    UseTitle("We cater for  your celebrations ");
    const {celebrationsId} = useParams();
    const { state } = useLocation();
   
    return (
        
        <div>
            {celebrationsId ? <CelebrationDisplay subCategories={state.subCategories}/> : "All Celebrations"}
          
        </div>
      )
}
