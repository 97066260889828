import React, { createContext, useEffect, useState } from "react";

export const PoojaContext = createContext(null);

const getLocalCartData = () => {
    let localCartData = localStorage.getItem(sessionStorage.getItem("userId"));

    if(localCartData === null || (typeof localCartData === "undefined") || localCartData.length === 0) {
        
        let cart = [];          
        return cart;
    
    } else {
        return JSON.parse(localCartData);
    }
}

const getDefaultCart = () =>{
        return getLocalCartData();    
 }

const PoojaContextProvider = (props) => {
    const [menu, setMenu] = useState();
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [celebrationName, setCelebrationName] = useState([]);
    const [loginUserDetails, setLoginUserDetails] = useState([]);
    const [userSelectedSubCategories, setUserSelectedSubCategories] = useState([]);
  

    const updateProducts = (products) => {
        setProducts(products);
    };

    const updateCategories = (categories) => {
        setCategories(categories);
    };

    const updateCelebrationName = (celebrationName) => {
        setCelebrationName(celebrationName);
    }

    const updateLoginUserDetails = (userDetails) => {
        setLoginUserDetails(userDetails);
    }

    const addToUserSelectedSubCategories = (subCategory) => {
        setUserSelectedSubCategories([...userSelectedSubCategories, subCategory]);
    }

    const [cartItems, setCartItems] = useState(getLocalCartData());
    

    const addToCart = (itemId, item) => {
        const index = cartItems.findIndex((e) => e.id === (itemId));
        if(index >= 0 && cartItems[index].selectedUnit === item.selectedUnit){  
           
            let quan = cartItems[index].quantity+(item.quantity > 0 ? 1 : 0);
            cartItems[index].quantity = quan;
            setCartItems(cartItems);
        } else {
            
           setCartItems(cartItems => [...cartItems, item])
        }   
    };

    const removeFromCart = (itemId, unit) => {
        const index = cartItems.findIndex((e) => (e.id === (itemId) && e.selectedUnit === unit));
        let filteredArray = cartItems.filter((_, i) => i !== index);
        setCartItems(filteredArray);
        
    };

    const reduceQuantityForanItemFromCart = (itemId, unit) => {
        const index = cartItems.findIndex((e) => (e.id === (itemId) && e.selectedUnit === unit));
        if(cartItems[index].quantity>1){
            cartItems[index].quantity = (cartItems[index].quantity -1)
            setCartItems(cartItems)
        }else{
            removeFromCart(itemId, unit);
        }
    }

    const clearCart = () => {
        if(sessionStorage.getItem("userId") != null) {
            localStorage.removeItem(sessionStorage.getItem("userId"));
            setCartItems(getDefaultCart());

        }
    }

    const getCartTotalAmount = () => {
        let totalAmount = 0;
        
        for (let i=0;i<cartItems.length;i++) {
            
            if (cartItems[i].quantity > 0) {
                
                let itemInfo = cartItems[i].price;
                totalAmount += itemInfo * cartItems[i].quantity;
            }
        }
        return totalAmount;
    }

    const getTotalCartItems = () => {
        let totalItems = 0;
        
        for (let i=0;i<cartItems.length;i++) {
            
            if (cartItems[i].quantity > 0) {
           
                totalItems += cartItems[i].quantity;
            }
        }
        return totalItems;
    }

    useEffect (() => {
        if(sessionStorage.getItem("userId") !=null){
            localStorage.setItem(sessionStorage.getItem("userId"), JSON.stringify(cartItems));
        }
        
    }, [cartItems])

    const contextValue = { getCartTotalAmount, cartItems, addToCart, removeFromCart, reduceQuantityForanItemFromCart, clearCart, getTotalCartItems, products, 
        updateProducts, categories, updateCategories, updateCelebrationName, celebrationName, updateLoginUserDetails, 
        loginUserDetails, addToUserSelectedSubCategories, userSelectedSubCategories, menu, setMenu };

    return (
        <PoojaContext.Provider value={contextValue} >
            {props.children}
        </PoojaContext.Provider>
    )
}

export default PoojaContextProvider;