import React, { useContext } from 'react'
import './CSS/PoojaCategory.css'
import { PoojaContext } from '../Context/PoojaContext'
import Item from '../Components/Item/Item'
import { useLocation } from 'react-router-dom'
import { UseTitle } from '../Components/Hero/UseTitle'

const PoojaCategory = (props) => {
  UseTitle("Our Trusted Products");
  const {products} =useContext(PoojaContext);
  const search = useLocation().search;
  const searchTerm = new URLSearchParams(search).get("q");
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const poojaProducts = products.filter((product) => (product.categories).includes(props.category));


  return (
    <div className='pooja-category'>
      <img className='poojacategory-banner' src={props.banner} alt="" />
      <div className="poojacategory-indexSort">
        <p>
          <span> Showing {poojaProducts.length} </span> out of {poojaProducts.length} {props.category} Products
        </p>
      </div>
      <div className="poojacategory-products">
          {poojaProducts.map((item, i) => {

                return <Item key={i} id={item.id} productName={item.productName} productKannadaName={item.productKannadaName} mainImage={item.mainImage} 
                newPrice={item.defaultNewPrice} oldPrice={item.defaultOldPrice} defaultUnit={item.defaultUnitOfMeasure} stock={item.stock}  unitOfMeasure={item.unitOfMeasure}
                />
            
        })}
      </div>
      {/**<div className="poojacategory-loadmore">
        Load More
      </div>*/}
    </div>
  )
}

export default PoojaCategory;