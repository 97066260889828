import React, { useContext, useRef, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Modal } from 'react-bootstrap';
import './ProductUpload.css'
import { PoojaContext } from '../../Context/PoojaContext';
import { toast } from 'react-toastify';
import axios from 'axios';

export const ProductUpload = () => {
  const { categories } = useContext(PoojaContext);
  const [show, setShow] = useState(false);
  const [mainImage, setMainImage] = useState();
  const [subImages, setSubImages] = useState([]);
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  const apiImageUploadUrl = `${baseURL}/image`;
  const addProductUrl = `${baseURL}/product`
  const productName = useRef();
  const productKannadaName = useRef();
  const description = useRef();
  const productCategory = useRef();
  const subCategory = useRef();
  const unitOfMeasure = useRef();
  const oldPrice = useRef();
  const newPrice = useRef();
  const [defaultUnit,setDefaultUnit] = useState();
  const [defaultOldPrice, setDefaultOldPrice] = useState();
  const [defaultNewPrice, setDefaultNewPrice] = useState();
  const availableStock = useRef();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [unitOfMeasureList, setUnitOfMeasureList]= useState([{unitOfMeasure:'', oldPrice:'', newPrice:''}]);
  const [validated, setValidated] = useState(false);
  
  const handleinputchange=(e, index)=>{
    const {name, value}= e.target;
    const list= [...unitOfMeasureList];
    list[index][name]= value;
    setUnitOfMeasureList(list);

  }

  const handleradiochange=(e, index,unitOfMeasure)=>{
    const list=[...unitOfMeasureList];
    const defaultUnit = list.splice(index,1);
    setDefaultUnit(defaultUnit[0].unitOfMeasure);
    setDefaultOldPrice(defaultUnit[0].oldPrice);
    setDefaultNewPrice(defaultUnit[0].newPrice);

  }
 
  const handleremove= index=>{
    const list=[...unitOfMeasureList];
    list.splice(index,1);
    setUnitOfMeasureList(list);
  }

  const handleaddclick=()=>{ 
    setUnitOfMeasureList([...unitOfMeasureList, {unitOfMeasure:'', oldPrice:'', newPrice:''}]);
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const uploadImage = (formData) => {
    axios.post(apiImageUploadUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
      .then((res) => toast.success(res.data))
      .catch((error) => toast.error('Error uploading images:', error));
  }

  const handleUpload = () => {
    if ((typeof mainImage !== "undefined")) {
      const formData = new FormData();
      formData.append('image', mainImage);
      uploadImage(formData);
    }

    Array.from(subImages).forEach((image) => {
      const formData = new FormData();
      formData.append('image', image);
      uploadImage(formData);
    })
    toast.done('Products images are uploaded successfully')
    handleClose();

  }

  const chngFn = (event) => {
    const { name, value } = event.target;
    let isChecked = event.target.checked;

    if(isChecked && name === "productCategory"){
      setSelectedCategories([...selectedCategories, value]);
    
    } else if(name === "productCategory"){
      setSelectedCategories(selectedCategories.filter((category) => (category != value)));
    }

    if(isChecked && name === 'subCategory'){
      setSelectedSubCategories([...selectedSubCategories, value]);
    }else if(name === "subCategory"){
      setSelectedSubCategories(selectedSubCategories.filter((subCategory) => (subCategory != value))); 
    }
  
};

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if((typeof mainImage === "undefined")){
      toast.error('Please upload main product image');
      setValidated(false);
    } else {
      setValidated(true);
      let subImagesNameList = []
      for(let i =0; i<subImages.length;i++){
        subImagesNameList[i]=subImages[i].name;
      }
  
      let unitsOfMeasure = {};
  
      for(let i =0; i<unitOfMeasureList.length;i++){
        let listItem = unitOfMeasureList[i];

        let item = {
          [listItem.unitOfMeasure] : [listItem.oldPrice, listItem.newPrice]
        }
        unitsOfMeasure[listItem.unitOfMeasure] =  [listItem.oldPrice, listItem.newPrice];
      }
  
      let productDetails = {
        productName: productName.current.value,
        productKannadaName: productKannadaName.current.value,
        description: description.current.value,
        stock: availableStock.current.value,
        categories: selectedCategories,
        subCategories:selectedSubCategories,
        mainImage: mainImage.name,
        subImages: subImagesNameList,
        unitOfMeasure: unitsOfMeasure,
        defaultUnitOfMeasure: defaultUnit,
        defaultOldPrice: defaultOldPrice,
        defaultNewPrice: defaultNewPrice
      }
  
      
      axios.post(addProductUrl, productDetails)
        .then((res) => {toast.done(res.data); event.target.reset(); setValidated(false);})
        .catch((error) => {
          toast.error('Error adding product:', error);
      });
      setSelectedCategories([]);
      setSelectedSubCategories([]);
      setUnitOfMeasureList([{unitOfMeasure:'', oldPrice:'', newPrice:''}]);
      setDefaultUnit('');
      setDefaultNewPrice('');
      setDefaultOldPrice('');
      window.location.reload(false);
    }

  }


  return (
    <Form className="product-container" validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formGridProductName">
        <Form.Label className="product-label">Product Name</Form.Label>
        <Form.Control ref={productName}  className="product-fields" placeholder="Product name" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGridProductKannadaName">
        <Form.Label className="product-label">Product Kannada Name</Form.Label>
        <Form.Control ref={productKannadaName}  className="product-fields" placeholder="Product name in kannada" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridDescription">
        <Form.Label className="product-label">Product Description</Form.Label>
        <Form.Control ref={description}  className="product-fields" placeholder="About product" />
      </Form.Group>
      <Form.Label className="product-label">Select Categories and SubCategories for the product</Form.Label>
      {categories.map((category, i) => (
        <div key={i} className='product-category-main'>
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            ref={productCategory}
            label={category.displayName}
            value={category.name}
            name='productCategory'
            onChange={chngFn}
          />
          {category.subCategories.map((subcategory, index) => (
            <Form.Check // prettier-ignore
              type="checkbox"
              label={subcategory}
              ref={subCategory}
              className='product-subcategories'
              value={subcategory}
              name='subCategory'
              key = {index}
              onChange={chngFn}
            />
          ))}

        </div>
      ))}

{unitOfMeasureList.map((x,i) => {
  return(
      <InputGroup className="mb-3" key={i}>
        <InputGroup.Text className="product-label" >Unit Of Measure</InputGroup.Text>
        <Form.Control name="unitOfMeasure" ref={unitOfMeasure} className="product-fields"  placeholder="Unit Of Measure" onChange={ e=>handleinputchange(e,i)}  />
        <Form.Control name="oldPrice" ref={oldPrice} className="product-fields" aria-label="Amount (with dot and two decimal places)" placeholder="Old Price" onChange={ e=>handleinputchange(e,i)} />
        <Form.Control name="newPrice" ref={newPrice} className="product-fields" aria-label="Amount (with dot and two decimal places)" placeholder="New Price" onChange={ e=>handleinputchange(e,i)} />
        {
                  unitOfMeasureList.length-1!==i &&
                  <Button variant="primary" onClick={()=> handleremove(i)}>Remove</Button>
               }
               { unitOfMeasureList.length-1===i &&
               <Button variant="primary" onClick={ handleaddclick}>Add More</Button>
               }
                <Form.Check
                name='defaultUnit'
            type="radio"
            label="Make this default for the product."
            id="defaultUnit"
            onChange={ e=>handleradiochange(e,i,defaultUnit)} 
          />
      </InputGroup>
  );
})}


      <Form.Group as={Row} className="mb-3" controlId="formStock">
        <Form.Label className="product-label" column sm="2">
          Available Stock
        </Form.Label>
        <Col sm="4">
          <Form.Control ref={availableStock} type="number" className="product-fields" />
        </Col>
        <Col>
          <Button variant="primary" onClick={handleShow}>
            Upload Product Images
          </Button>
        </Col>
      </Form.Group>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select/Upload Product Main and Sub Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Select Single Main Image</Form.Label>
            <Form.Control type="file"
              onChange={(e) => {
                setMainImage(e.target.files[0]);
              }} />
          </Form.Group>
          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Select Multiple Sub-Images</Form.Label>
            <Form.Control type="file" multiple
              onChange={(e) => {
                setSubImages(e.target.files);
              }} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpload}>Upload</Button>
        </Modal.Footer>
      </Modal>


      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  )
}
