import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import './Dashboard.css';
import { UseTitle } from "../Hero/UseTitle";
import { Image } from "react-bootstrap";


export const DashboardCard = ({order}) => {
  UseTitle("Your Order Details");
  const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
  return (
        <Table responsive="lg" className="customtable shadow">
          <thead>
            <tr className="customtableheader">
              <th >Order Id:</th>
              
                <th >{order.id}</th>
                <th ></th>
                <th >Total Items in this order : {order.orderItems.length}</th>
                <th>Order Status: {order.orderStatus}</th>
             
            </tr>
            <tr >
                 <th >Sl No.</th>
                 <th >Product</th>
              <th></th>
                <th >Quantity</th>
                <th>Price</th>
                
             
            </tr>
          </thead>
          <tbody>
          { order.orderItems.map((orderItem, i) => (
            <tr key={i}>
              <td>{i+1}</td>
                <td > 
                <Link to={`/product/${orderItem.productId}`}>
                        
                        <Image src={`${baseURL}/image/${orderItem.mainImage}`} alt={orderItem.productName} rounded />
                    </Link>
                </td>
                <td>
                <Link to={`/product/${orderItem.productId}`}>
                            <p >{orderItem.productName}</p>
                        </Link>
                </td>
                <td>
                    {orderItem.quantity}
                </td>
                <td>Rs. {orderItem.quantity*orderItem.newPrice}</td>
            </tr>
           )) }
            <tr>
            <td colSpan={3}></td>
              <td ><h6>Total Amount</h6> </td>
              <td ><h6>{order.totalAmount}</h6></td>
            </tr>
          </tbody>
        </Table>
      
  )
}