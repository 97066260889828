import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import puja_banner from '../Assets/puja_items-banner.jpg';
import banner1 from '../Assets/banner1.jpg'
import banner2 from '../Assets/banner2.jpg'
import { useState } from 'react';
import Image from 'react-bootstrap/Image';
import './Hero.css';

const Hero = () => {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel className='hero' activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <Image src={banner1} fluid />
        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={banner2} fluid />
        <Carousel.Caption>
          <h3></h3>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={puja_banner} fluid />
        <Carousel.Caption>
          <h3></h3>
          <p>
            
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>












  )

}

export default Hero