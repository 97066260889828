import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { FiShoppingCart } from "react-icons/fi";
import { MdOutlineLockPerson } from "react-icons/md";
import { BsPersonLock } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import { PoojaContext } from '../../Context/PoojaContext'
import './Navbar.css'
import logo from '../Assets/logo.PNG'


function NavbarBootStrap() {
 
  const { getTotalCartItems, menu, setMenu } = useContext(PoojaContext);
  const { categories, celebrationName, updateCelebrationName } = useContext(PoojaContext);
  //const [dropDown, setDropdown] = useState(true);
  const accessToken = JSON.parse(sessionStorage.getItem("token"));
  const role = sessionStorage.getItem("role");
  const navigate = useNavigate();
  const userName = sessionStorage.getItem("userName");
  
  const updateMenuData = (cName, cId, subCategories) => {
   // setDropdown(false);
    const subCatList = subCategories;
   
    updateCelebrationName(subCatList[0]);
    navigate(`/celebrations/${cId}`, {state: {subCategories: subCatList}});
  }

  function handleLogout(){
    setMenu("home");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("role")
    navigate("/");
  }

  function handleMainMenuClick(menuName,path) {
    setMenu(menuName);
    if(path === 'login'){
      navigate(`/${path}`, {state:{signupStatus:false}});
    } else if(path === 'celebrations'){

    }else {
      navigate(`/${path}`);
    }
  }

  

  return (

    <Navbar expand="md" >
      <Container >
        <Navbar.Brand href="/" className="nav-logo">
          <Col >
            <Image src={logo} alt="UthasavaSoukyaLogo" roundedCircle width="100px" height="100px" />
          </Col>
          <p>ಉತ್ಸವ ಸೌಖ್ಯ</p>
        </Navbar.Brand>
        <Col >
          <Navbar.Toggle aria-controls="basic-navbar-nav"  />
          <Navbar.Collapse id="basic-navbar-nav" className="nav-menu" >
            { role==='ADMIN' ? 
            <Nav className="ms-auto" >
              {accessToken  && <Nav.Link className={menu === "addproducts" ? 'nav-menu-active' : ''}  onClick={() => {handleMainMenuClick("addproducts","addproducts") }}>Add Products</Nav.Link>}
            </Nav>
            : 
            <Nav className="ms-auto" >
              <Nav.Link className={menu === "home" ? 'nav-menu-active' : ''} onClick={() => {handleMainMenuClick("home","") }} >Home</Nav.Link>
              <Nav.Link  className={menu === "metal" ? 'nav-menu-active' : ''} onClick={() => { handleMainMenuClick("metal","metal") }}>Metal</Nav.Link>
              <Nav.Link  className={menu === "milk" ? 'nav-menu-active' : ''} onClick={() => { handleMainMenuClick("milk","milk") }}>Pure Desi Milk</Nav.Link>
              <NavDropdown title="Celebrations" id="basic-nav-dropdown" className={menu === "celebrations" ? 'nav-menu-active' : ''} onClick={() => {handleMainMenuClick("celebrations","celebrations") }}>
                {categories.map((item, i) => (
                  (item.section === "celebrations" && <NavDropdown.Item key={i} className="nav-submenu" onClick={() => updateMenuData(item.name, item.id, item.subCategories)}>
                    {item.displayName}
                  </NavDropdown.Item>)
                ))}
              </NavDropdown>
             {accessToken &&  <Nav.Link className={menu === "dashboard" ? 'nav-menu-active' : ''} onClick={() => { handleMainMenuClick("dashboard","dashboard") }}  >Dashboard</Nav.Link>}
             
            </Nav>
            }
            
          </Navbar.Collapse>

        </Col>
        <Col >
        {/*<Search/>*/}
        </Col>
        <Col md="auto" >
        <div className="nav-login">
        {accessToken ? 
        <Nav.Link onClick={handleLogout}  >
         
        {['bottom'].map((placement) => (
          <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
              <Tooltip id={`tooltip-${placement}`}>
                LogOut
              </Tooltip>
            }

            
          >
          <Button variant="secondary" ><MdOutlineLockPerson /></Button>
          </OverlayTrigger>
        ))}
      </Nav.Link>
        :
        
        <Nav.Link onClick={() => {(role ==='ADMIN' ? handleMainMenuClick("addproducts","login") : handleMainMenuClick("dashboard","login") )}} >

        {['bottom'].map((placement) => (
          <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
              <Tooltip id={`tooltip-${placement}`}>
                Login
              </Tooltip>
            }
          >
          <Button variant="secondary"><BsPersonLock /></Button>
          </OverlayTrigger>
        ))}
      </Nav.Link> 
        
        }
            { role !=='ADMIN' ? 
          <Nav className="ms-auto nav-login-cart" >

            <Nav.Link className={menu === "cart" ? 'nav-menu-active' : ''} onClick={() => {handleMainMenuClick("cart","cart") }} >
              {['bottom'].map((placement) => (
                <OverlayTrigger key={placement}
                  placement={placement}
                  overlay={
                    <Tooltip id={`tooltip-${placement}`}>
                      Go To Cart
                    </Tooltip>
                  }>
                  <Button variant="secondary"><FiShoppingCart /></Button>

                </OverlayTrigger>
              ))}
            </Nav.Link>
            <span className="nav-cart-count">{getTotalCartItems()}</span>
          </Nav>
          : <Nav></Nav>
            }
          </div>
          {accessToken ? <div className='login_user_welcometext'> Welcome {userName}</div> : ""}
        </Col>
        <Col md="auto">
        
        </Col>
      </Container>
    </Navbar>
  );
}

export default NavbarBootStrap;