import React from 'react';
import './Breadcrum.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Breadcrums = (props) => {
  const { product } = props;

  return (
    <Breadcrumb>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      {/*<Breadcrumb.Item href={`/product/${product.productId}`} >
        {product.category}
      </Breadcrumb.Item>*/}
      <Breadcrumb.Item active>{props.productName}</Breadcrumb.Item>
    </Breadcrumb>

  )
}

export default Breadcrums