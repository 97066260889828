import Button from 'react-bootstrap/Button';
import './CSS/PageNotFound.css'
import { UseTitle } from '../Components/Hero/UseTitle';

export const PageNotFound = () => {
    UseTitle("Page Not Found");
    return (
      <main>
          <section className='pagenot_found'>
              <div >
                  <p >404, Oops!</p>
                  <p >Resource Not Found</p>
                  <div >
                      <img  src="" alt="" />
                  </div>
              </div>
              <div >
                  <a href="/">
                  <Button variant="secondary">Back To Home</Button>{' '}
                  </a>
              </div>
          </section>
      </main>
    )
  }